// src/utils/dateUtils.js
export const formatDate = (timestamp) => {
  // console.log('formatDate received timestamp:', timestamp);
  if (!timestamp) return 'N/A';

  let date;
  try {
    // Checking for Firebase timestamp format
    if (timestamp.seconds) {
      date = new Date(timestamp.seconds * 1000);
    } else if (typeof timestamp === 'number' || typeof timestamp === 'string') {
      // Handling standard JavaScript timestamp (milliseconds since epoch) or an ISO string
      date = new Date(timestamp);
    } else {
      throw new Error('Unrecognized timestamp format');
    }

    const formattedDate = date.toLocaleDateString('zh-CN');
    // console.log('Formatted date:', formattedDate);
    return formattedDate;
  } catch (error) {
    // console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};
