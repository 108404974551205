// client/src/components/ProductCreate.js
import React, { useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import { countryData } from '../utils/countryUtils';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ProductPrices from './ProductPrices';
import ProductFaqs from './ProductFaqs';
import ProductTags from './ProductTags';
import useProductForm from '../hooks/useProductForm';

const styles = {
    container: {
        padding: '20px',
        lineHeight: '1.6',
        fontSize: '16px',
    },
    box: {
        marginBottom: '20px',
        borderBottom: '1px solid #eee'
    },
    grid: {
        alignItems: 'center',
        marginBottom: '10px'
    },
    addRemoveBtn: {
        marginLeft: '10px',
    }
};

const ProductCreate = () => {
    const initialProductState = {
        brand: '',
        imageUrl: '',
        name: '',
        prices: {},
        tags: [],
        faqs: Array.from({ length: 10 }, () => ({ question: '', answer: '' }))
    };
    const navigate = useNavigate();
    const sourceOptions = ['官網', 'Amazon', '豐澤', 'Klook', 'KKday', 'Trip.com'];

    const {
        product,
        setProduct,
        newTag,
        handlePriceChange,
        handleUrlChange,
        handleFaqChange,
        handleChange,
        handleRemoveTag,
        handleCountryChange,
        handleSourceChange,
        handleRemovePriceSource,
        handleAddPriceSource,
        handleAddPriceCountry,
        unusedCountryOptions,
        handleNewTagChange,
        handleNewTagKeyPress,
        generateQuestion,
        generateAnswer
    } = useProductForm(initialProductState);

    const getAvailableCountriesForDropdown = (currentCountryKey) => {
        const usedCountries = new Set(Object.keys(product.prices));
        usedCountries.delete(currentCountryKey);
        return Object.keys(countryData).filter(country => !usedCountries.has(country));
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                if (!idTokenResult.claims.admin) {
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${BASE_URL}/api/products`, product);
            navigate('/admin/products');
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
        <div style={styles.container}>
            <h2>新增產品</h2>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <TextField
                        label="名稱"
                        name="name"
                        value={product.name}
                        onChange={handleChange}
                        required
                        fullWidth
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="品牌"
                        name="brand"
                        value={product.brand}
                        onChange={handleChange}
                        required
                        fullWidth
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="圖片網址"
                        name="imageUrl"
                        value={product.imageUrl}
                        onChange={handleChange}
                        required
                        fullWidth
                    />
                </Box>
                <ProductPrices
                prices={product.prices}
                sourceOptions={sourceOptions}
                handlePriceChange={handlePriceChange}
                handleUrlChange={handleUrlChange}
                handleCountryChange={handleCountryChange}
                handleSourceChange={handleSourceChange}
                handleRemovePriceSource={handleRemovePriceSource}
                handleAddPriceSource={handleAddPriceSource}
                handleAddPriceCountry={handleAddPriceCountry}
                getAvailableCountriesForDropdown={getAvailableCountriesForDropdown}
                unusedCountryOptions={unusedCountryOptions}
                />
                <ProductFaqs
                    faqs={product.faqs}
                    handleFaqChange={handleFaqChange}
                    generateQuestion={generateQuestion}
                    generateAnswer={generateAnswer}
                />
                <ProductTags
                    tags={product.tags}
                    newTag={newTag}
                    handleRemoveTag={handleRemoveTag}
                    handleNewTagChange={handleNewTagChange}
                    handleNewTagKeyPress={handleNewTagKeyPress}
                />
                <Button type="submit" variant="contained" color="primary">新增產品</Button>
            </form>
        </div>
    );
};

export default ProductCreate;