// client/src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
// import AddProduct from './components/AddProduct';
import ExchangeRatePage from './components/ExchangeRatePage';
import TaggedProductList from './components/TaggedProductList';
import SearchResults from './utils/SearchResults';
import { auth } from './services/firebase';
import SimpleLogin from './components/SimpleLogin';
import NavBar from './components/NavBar';
import SignUp from './components/SignUp';
import Profile from './components/Profile';
// import AdminDashboard from './components/AdminDashboard';
import AboutUs from './components/AboutUs';
import TermsAndConditions from './components/TermsAndConditions';
import Footer from './components/Footer';
import BrandProductList from './components/BrandProductList';
import BrandListing from './components/BrandListing';
import AdminProductManagement from './components/AdminProductManagement';
import ProductEdit from './components/ProductEdit';
import ProductCreate from './components/ProductCreate';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0F2A38'
    },
    secondary: {
      main: '#757ce8',
    },
  },
  typography: {
    fontSize: 14,
    h6: {
      fontSize: '1.25rem',
    },
    button: {
      textTransform: 'none',
    },
  },
});

// Custom hook to scroll to top on route change
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  const [user, setUser] = useState(null);
  console.log("Current Environment:", process.env.NODE_ENV);
  console.log("Current User:", user);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is signed in, use their UID as the user token
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          algoliaUserToken: authUser.uid,
        });
        setUser(authUser);
        console.log('User logged in:', authUser);
      } else {
        // User is signed out
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          algoliaUserToken: 'anonymous', // or you can choose not to push anything
        });
        setUser(null);
        console.log('No user logged in');
      }
    });

    return () => unsubscribe();
  }, []);

  // Add logout function
  const handleLogout = () => {
    auth.signOut().then(() => {
      console.log('User logged out');
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <NavBar user={user} credits={"100"} onLogout={handleLogout} onLogin={() => { /* handle login */ }} />
        <Routes>
          <Route path="/" element={
            <ProductList 
              title="一價網｜比較世界各地同一商品服務價格" 
              canonicalUrl={`${window.location.origin}/`} 
            />} 
          />
          <Route path="/product/:productId" element={<ProductDetail user={user} />} />
          <Route path="/exchange-rates" element={<ExchangeRatePage />} />
          <Route path="/tag/:tag" element={<TaggedProductList />} />
          <Route path="/search" element={<SearchResults user={user} />} />
          <Route path="/login" element={<SimpleLogin />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin/products" element={<AdminProductManagement />} />
          <Route path="/create" element={<ProductCreate />} />
          <Route path="/edit/:productId" element={<ProductEdit />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/brand/:brand" element={<BrandProductList />} />
          <Route path="/brands" element={<BrandListing />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;