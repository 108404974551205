// client/src/components/ProductList.js
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Grid, Button, Box, Pagination, TextField } from '@mui/material';
import axios from 'axios';
import { convertPriceToHKD } from '../utils/currencyUtils';
import { formatDate } from '../utils/dateUtils';
import { BASE_URL } from '../config';
import { setCache, getCache } from '../utils/cacheUtils';
import useExchangeRates from '../hooks/useExchangeRates';
import useLoading from '../hooks/useLoading';
import LoadingIndicator from './LoadingIndicator';
import CustomSnackbar from './CustomSnackbar';
import AdComponent from './AdComponent';

function ProductList({ products: propProducts, title, canonicalUrl, searchQuery, queryID, isFromCache }) {
  const [products, setProducts] = useState([]);
  const { exchangeRates } = useExchangeRates();
  const { loading, startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page')) || 1);
  const itemsPerPage = 12;
  const [suggestedProductName, setSuggestedProductName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const cacheKey = 'products';
        const cachedProducts = getCache(cacheKey);
        if (cachedProducts && !propProducts) {
          setProducts(cachedProducts);
          console.log('Using cached products');
        } else if (propProducts) {
          setProducts(propProducts);
          console.log(isFromCache ? 'Using cached products' : 'Using provided products');
        } else {
          const response = await axios.get(`${BASE_URL}/api/products`);
          setProducts(response.data);
          setCache(cacheKey, response.data);
          // console.log('Products fetched:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [propProducts, isFromCache, startLoading, stopLoading]);

  useEffect(() => {
    // Update currentPage based on URL change
    const pageFromURL = parseInt(searchParams.get('page')) || 1;
    setCurrentPage(pageFromURL);
  }, [searchParams]);

    const countSources = (prices) => {
      return Object.values(prices).reduce((acc, priceInfo) => {
        return acc + Object.keys(priceInfo.sources || {}).length;
      }, 0);
    };

    const displayTags = (tags) => {
      return tags.map((tag, index) => (
        <Link to={`/tag/${tag}`} key={index} style={{ textDecoration: 'none', cursor: 'pointer', marginRight: 5 }}>
          <Typography variant="body1" color="textSecondary" component="span">
            {tag}
          </Typography>
        </Link>
      ));
    };

  // Function to handle pagination change
  const handlePageChange = (event, value) => {
    setSearchParams({ page: value });
    window.scrollTo(0, 0);
  };

    // Calculate the total number of pages
    const totalPages = Math.ceil(products.length / itemsPerPage);

    // Move the isLastPage declaration here, after totalPages is defined
    const isLastPage = currentPage === totalPages;

    // Get only the products for the current page
    const paginatedProducts = products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const getLowestPriceEntry = (prices) => {
      const priceEntries = Object.entries(prices).flatMap(([countryCode, priceSources]) => {
        return Object.entries(priceSources.sources || {}).map(([sourceName, sourceInfo]) => {
          const priceInHKD = convertPriceToHKD(sourceInfo.price, countryCode, exchangeRates);
          return {
            country: countryCode,
            source: sourceName,
            price: sourceInfo.price,
            priceInHKD: priceInHKD !== null ? parseFloat(priceInHKD) : null,
            url: sourceInfo.url
          };
        });
      });

      return priceEntries.reduce((lowest, entry) => {
        if (entry.priceInHKD !== null) {
          return lowest === null || entry.priceInHKD < lowest.priceInHKD ? entry : lowest;
        }
        return lowest;
      }, null);
    };

    if (loading) {
      return <LoadingIndicator />;
    }

  const getHighestPriceEntry = (prices) => {
    const priceEntries = Object.entries(prices).flatMap(([countryCode, priceSources]) => {
      return Object.entries(priceSources.sources || {}).map(([sourceName, sourceInfo]) => {
        const priceInHKD = convertPriceToHKD(sourceInfo.price, countryCode, exchangeRates);
        return {
          country: countryCode,
          source: sourceName,
          price: sourceInfo.price,
          priceInHKD: priceInHKD !== null ? parseFloat(priceInHKD) : null,
          url: sourceInfo.url
        };
      });
    });

    return priceEntries.reduce((highest, entry) => {
      if (entry.priceInHKD !== null) {
        return highest === null || entry.priceInHKD > highest.priceInHKD ? entry : highest;
      }
      return highest;
    }, null);
  };

  // Function to handle suggestion submission
  const handleSuggestProduct = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/suggestProduct`, { name: suggestedProductName });
      console.log('Suggested product submitted:', response.data);
      setSuggestedProductName('');  // Clear the input after successful submission
      setSnackbarMessage('已收到你提交的資料，我們將盡快追蹤其價格。');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting suggested product:', error);
      setSnackbarMessage('提交產品建議時發生錯誤');
      setSnackbarOpen(true);
    }
  };

  const goToProductDetail = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <>
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <AdComponent />
    <Grid container spacing={2} sx={{ marginTop: 0 }}>
      {paginatedProducts.map((product, index) => {
        const lowestPriceEntry = getLowestPriceEntry(product.prices);
        const sourcesCount = countSources(product.prices);
        const highestPriceEntry = getHighestPriceEntry(product.prices);
        const savings = highestPriceEntry && lowestPriceEntry ? highestPriceEntry.priceInHKD - lowestPriceEntry.priceInHKD : 0;
  
        return (
          <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}
              data-insights-index="products"
              data-insights-object-id={product.id}
              data-insights-position={index + 1 + (currentPage - 1) * itemsPerPage}
              data-insights-query-id={queryID}>
            <Card sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              borderRadius: '16px',
              overflow: 'hidden',
            }}>
              <CardMedia
                sx={{ height: 0, paddingTop: '56.25%' }} // 16:9 aspect ratio
                image={product.imageUrl || 'default-placeholder-image-url.jpg'}
                title={product.name}
              />
              <CardContent sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '16px',
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography gutterBottom variant="h6" component="h2" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%',}}>
                  {product.name}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    {formatDate(product.updateTime)}
                  </Typography>
                  </Box>
                {lowestPriceEntry && (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6" component="p" style={{ fontWeight: 'bold', color: '#4caf50'}}>
                    HKD {lowestPriceEntry.priceInHKD.toFixed(2)} 
                    <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 4 }}>
                      (差價 ${savings.toFixed(2)})
                    </Typography>
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {`${sourcesCount}個來源`}
                  </Typography>
                </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {product.tags && (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 1 }}>
                    {displayTags(product.tags)}
                  </Box>
                )}
                  <Button
                    className="btn-view-details"
                    variant="outlined"
                    type="button"
                    onClick={() => goToProductDetail(product.id)}
                  >
                    查看
                  </Button>
                  </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    {isLastPage && (
      <Grid item xs={12} container justifyContent="center" sx={{ marginTop: 2 }}>
        <TextField
          label="沒有找到你想的產品？"
          variant="outlined"
          value={suggestedProductName}
          onChange={(e) => setSuggestedProductName(e.target.value)}
          sx={{ marginRight: 2, width: '60%' }}
        />
        <Button variant="contained" onClick={handleSuggestProduct}>
          提交建議
        </Button>
      </Grid>
    )}
    <CustomSnackbar
      open={snackbarOpen}
      message={snackbarMessage}
      handleClose={() => setSnackbarOpen(false)}
    />
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, width: '100%' }}>
      <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ margin: 'auto' }} />
    </Box>
    </Grid>
    </>
  );
}

  export default ProductList;