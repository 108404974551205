// src/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  const styles = {
    container: {
      padding: '20px',
      lineHeight: '1.6',
      fontSize: '16px',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
    }
  };

  document.title = `使用條款`;

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Terms and Conditions</h1>
      <h2>Introduction</h2>
        <p>
        Welcome to our website. By accessing and using our website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern our relationship with you in relation to this website.
        </p>

        <h2>Privacy Policy</h2>
        <p>
        Your privacy is important to us. Our privacy policy outlines how we collect, use, store, and protect your personal information, and the choices you have regarding our use of your information. By using our site, you consent to our privacy policy.
        </p>

        <h2>Use License</h2>
        <p>
        The content on this website is provided for your personal, non-commercial use only. This is the grant of a license, not a transfer of title, and under this license, you may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from the website.
        </p>

        <h2>Disclaimer</h2>
        <p>
        The materials on our website are provided "as is" without any express or implied warranty of any kind. We do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on our website or otherwise relating to such materials or on any sites linked to this site.
        </p>

        <h2>Limitations</h2>
        <p>
        In no event shall we or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on our website, even if we or an authorized representative has been notified orally or in writing of the possibility of such damage.
        </p>

        <h2>Revisions and Errata</h2>
        <p>
        The materials appearing on our website could include technical, typographical, or photographic errors. We does not warrant that any of the materials on its website are accurate, complete, or current. We may make changes to the materials contained on our website at any time without notice.
        </p>

        <h2>Links</h2>
        <p>
        Our site may contain links to third-party websites. These links are provided for your convenience. We have no control over the content of these external sites and accept no responsibility for them or for any loss or damage that may arise from your use of them.
        </p>

        <h2>Site Terms of Use Modifications</h2>
        <p>
        We may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound by the then-current version of these terms and conditions.
        </p>

        <h2>Governing Law</h2>
        <p>
        Any claim relating to our website shall be governed by the laws of Hong Kong without regard to its conflict of law provisions.
        </p>

        <h2>Contact Information</h2>
        <p>
        If you have any questions about these terms and conditions, please contact us at hi@schooldays.xyz.
        </p>
    </div>
  );
}

export default TermsAndConditions;