// src/components/Profile.js
import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { Container, Typography, Button, List, ListItem, ListItemText, IconButton, ListItemButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config';
import LoadingIndicator from './LoadingIndicator';
import useLoading from '../hooks/useLoading';

function Profile() {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();
  const [bookmarkedProducts, setBookmarkedProducts] = useState([]);
  const { loading, startLoading, stopLoading } = useLoading();

  const fetchBookmarks = useCallback(async () => {
    if (!auth.currentUser) {
      return;
    }
    startLoading();
    const userId = auth.currentUser.uid;
    const bookmarkRef = doc(db, 'bookmarks', userId);
    try {
      const bookmarkDoc = await getDoc(bookmarkRef);
      if (bookmarkDoc.exists()) {
        const bookmarkedIds = bookmarkDoc.data().products || [];
        const products = await Promise.all(bookmarkedIds.map(async (id) => {
          const response = await axios.get(`${BASE_URL}/api/products/${id}`);
          return response.data;
        }));
        setBookmarkedProducts(products);
      }
    } catch (error) {
      console.error('Error fetching bookmarks:', error);
    } finally {
      stopLoading();
    }
  }, [auth.currentUser, db, startLoading, stopLoading]);

  useEffect(() => {
    fetchBookmarks();
  }, [fetchBookmarks]);

  const handleRemoveBookmark = async (productId, event) => {
    event.stopPropagation(); // Prevents the ListItem onClick from being triggered

    const userId = auth.currentUser.uid;
    const bookmarkRef = doc(db, 'bookmarks', userId);

    try {
      await updateDoc(bookmarkRef, {
        products: arrayRemove(productId)
      });
      fetchBookmarks(); // Refresh the bookmarks
    } catch (error) {
      console.error('Error removing bookmark:', error);
    }
  };
  
  // Function to handle the sign-out process
  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('User signed out.');
      navigate('/login'); // Redirect to login page after sign out
    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };

  // Check if the user is logged in and get their email
  const userEmail = auth.currentUser ? auth.currentUser.email : '未登錄';

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        用戶資料
      </Typography>
      <Typography variant="h6">
        電子郵件: {userEmail}
      </Typography>
      <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        我的收藏
      </Typography>

      {loading ? <LoadingIndicator /> : (
      <List>
        {bookmarkedProducts.map(product => (
          <ListItem 
            key={product.id} 
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={(event) => handleRemoveBookmark(product.id, event)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton onClick={() => navigate(`/product/${product.id}`)}>
              <ListItemText primary={product.name} primaryTypographyProps={{ style: { textDecoration: 'underline', color: '#1976d2' } }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      )}

      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        style={{ marginTop: '1rem' }}
      >
        登出
      </Button>
    </Container>
  );
}

export default Profile;