// src/ExchangeRatePage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Container, Grid, Paper } from '@mui/material';
import { countryData, getNameByCountryCode, getFlagByCountryCode } from '../utils/countryUtils';
import { BASE_URL } from '../config';
import { Helmet } from 'react-helmet';

function ExchangeRatePage() {
    const [exchangeRates, setExchangeRates] = useState({});

    useEffect(() => {
        const fetchExchangeRates = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/exchangeRates`);
                setExchangeRates(response.data);
                console.log('Exchange rates fetched:', response.data);
            } catch (error) {
                console.error('Error fetching exchange rates:', error);
            }
        };

        fetchExchangeRates();
    }, []);

    const toSignificantDigits = (num, n) => {
        if (num === 0) return '0';
        const d = Math.ceil(Math.log10(num < 0 ? -num : num));
        const power = n - d;
        const magnitude = Math.pow(10, power);
        const shifted = Math.round(num * magnitude);
        return shifted / magnitude;
    };

    const filteredRates = Object.entries(exchangeRates)
        .map(([key, value]) => {
            // Find the country that matches the currency code
            const countryCode = Object.keys(countryData).find(code => countryData[code].currency === key);
            if (!countryCode) {
                // If there is no matching country code in countryData, return null
                return null;
            }
            const flag = getFlagByCountryCode(countryCode);
            const countryName = getNameByCountryCode(countryCode);
            return {
                country: `${flag} ${countryName}`,
                rate: toSignificantDigits(1 / value, 3)
            };
        })
        .filter(rate => rate !== null); // This will remove any null entries from the array

    return (
        <Container>
            <Helmet>
                <title>匯率</title>
                <link rel="canonical" href={`${window.location.origin}/exchange-rates`} />
            </Helmet>
            <Typography variant="h5" gutterBottom style={{ margin: '20px 0' }}>
                匯率
            </Typography>
            <Grid container spacing={3}>
                {filteredRates.map(({ country, rate }) => (
                    <Grid item xs={12} sm={6} md={4} key={country}>
                        <Paper sx={{ padding: 2, textAlign: 'center', color: 'text.secondary' }}>
                            <Typography variant="h6">
                                {country}
                            </Typography>
                            <Typography variant="body1">
                                {rate}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default ExchangeRatePage;
