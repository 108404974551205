// client/src/components/ProductFaqs.js
import React from 'react';
import { Box, Grid, TextField, Button, Paper } from '@mui/material';

const ProductFaqs = ({ faqs, handleFaqChange, generateQuestion, generateAnswer }) => {
    return (
        <Box mb={2}>
            <h4>常見問題</h4>
            {faqs.map((faq, index) => (
                <Paper key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={9} sm={9}>
                            <TextField
                                label={`問題 ${index + 1}`}
                                value={faq.question}
                                onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="outlined"
                                onClick={() => generateQuestion(index)}
                            >
                                AI
                            </Button>
                        </Grid>
                        <Grid item xs={9} sm={9}>
                            <TextField
                                label={`解答 ${index + 1}`}
                                value={faq.answer}
                                onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="outlined"
                                onClick={() => generateAnswer(index)}
                            >
                                AI
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            ))}
        </Box>
    );
};

export default ProductFaqs;
