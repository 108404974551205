// client/src/components/ProductDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Link, 
  Card, 
  CardContent, 
  Grid, 
  CardMedia,
  Button,
  Box,
  ListItemButton,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';
import { getFirestore, doc, setDoc, arrayUnion } from 'firebase/firestore';
import axios from 'axios';
import useExchangeRates from '../hooks/useExchangeRates';
import useLoading from '../hooks/useLoading';
import LoadingIndicator from '../components/LoadingIndicator';
import { convertPriceToHKD } from '../utils/currencyUtils';
import { formatDate } from '../utils/dateUtils';
import { countryData, getCurrencyByCountryCode } from '../utils/countryUtils';
import { BASE_URL } from '../config';
import { setCache, getCache } from '../utils/cacheUtils';
import CustomSnackbar from './CustomSnackbar';
import { Helmet } from 'react-helmet';
import { RelatedProducts } from '@algolia/recommend-react';
import algoliarecommend from '@algolia/recommend';
import AdComponent from './AdComponent';

function ProductDetail({ user }) { 
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { productId } = useParams();
  const [localProduct, setLocalProduct] = useState(null);
  const [error, setError] = useState('');
  const { exchangeRates, loading: exchangeRatesLoading, error: exchangeRatesError } = useExchangeRates();
  const { loading, startLoading, stopLoading } = useLoading();
  const canonicalUrl = `${window.location.origin}/product/${productId}`;
  const recommendClient = algoliarecommend(
    '80HKEWBAW9',
    'f1f23ee825d8fb063f46c14cd426c67c'
  );

  useEffect(() => {
    const fetchProduct = async () => {
      startLoading();
      try {
          const cacheKey = `product-${productId}`;
          const cachedProduct = getCache(cacheKey);
          if (cachedProduct) {
              setLocalProduct(cachedProduct);
              console.log('Using cached product details');
          } else {
              const response = await axios.get(`${BASE_URL}/api/products/${productId}`);
              setLocalProduct(response.data);
              setCache(cacheKey, response.data);
              // console.log('Product fetched:', response.data);
          }
      } catch (error) {
          console.error('Error fetching product:', error);
          setError('產品未找到');
      } finally {
          stopLoading();
      }
    };

    fetchProduct();
  }, [productId, startLoading, stopLoading]);

  if (loading || exchangeRatesLoading) {
    return <LoadingIndicator />; //
  }

  if (error || exchangeRatesError) {
      return <Typography>{error || exchangeRatesError}</Typography>;
  }

  if (!localProduct) {
      return <Typography>未找到產品</Typography>;
  }

// Function to share product
const shareProduct = async () => {
  const productUrl = `${window.location.origin}/product/${productId}?utm_source=share_button`;
  const shareData = {
    title: `查看${localProduct.name}各國價格比較`,
    text: `查看${localProduct.name}各國價格比較`,
    url: productUrl,
  };

  if (navigator.share) {
    try {
      await navigator.share(shareData);
      console.log('Product shared successfully');
    } catch (error) {
      console.error('Error sharing the product:', error);
    }
  } else {
    navigator.clipboard.writeText(productUrl).then(() => {
      console.log('Product URL copied to clipboard');
    }, (err) => {
      console.error('Could not copy product URL to clipboard:', err);
    });
  }
};

const renderFAQs = () => {
  const filteredFAQs = (localProduct.faqs || []).filter(faq => faq.question && faq.answer);

  if (filteredFAQs.length > 0) {
    // Create a structured data object for SEO
    const structuredFAQs = filteredFAQs.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }));

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": structuredFAQs
    };

    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        {filteredFAQs.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  } else {
    // Return empty fragment if there are no FAQs
    return <></>;
  }
};
  
  // Function to render table rows sorted by price in HKD
  const renderTableRows = () => {
    // Flatten and sort the price data
    const sortedPrices = Object.entries(localProduct.prices || {})
      .flatMap(([countryCode, priceSources]) => {
        return Object.entries(priceSources.sources || {}).map(([sourceName, sourceInfo]) => {
          const priceInHKD = exchangeRates ? convertPriceToHKD(sourceInfo.price, countryCode, exchangeRates) : null;
          return {
            countryCode,
            sourceName,
            sourceInfo,
            priceInHKD
          };
        });
      })
      .filter(entry => entry.priceInHKD !== null)
      .sort((a, b) => a.priceInHKD - b.priceInHKD);

    // Render the sorted rows
    return sortedPrices.map(({ countryCode, sourceName, sourceInfo, priceInHKD }, index) => {
      const countryName = countryData[countryCode]?.name || countryCode;
      const currency = getCurrencyByCountryCode(countryCode);
      const sourceLabel = `${countryName}${sourceName}`;

      return (
        <TableRow key={`${countryCode}-${sourceName}-${index}`}>
          <TableCell sx={{ fontSize: '1.2rem' }}>{sourceLabel}</TableCell>
          <TableCell align="right" sx={{ fontSize: '1.2rem' }}>{`${currency} ${sourceInfo.price}`}</TableCell>
          <TableCell align="right" sx={{ fontSize: '1.2rem' }}>{`${priceInHKD}`}</TableCell>
          <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
            <Link href={sourceInfo.url} target="_blank" rel="noopener noreferrer">查看</Link>
          </TableCell>
        </TableRow>
      );
    });
  };
  
  // Function to generate sorted chart data
  const generateSortedChartData = () => {
    return Object.entries(localProduct.prices || {})
      .flatMap(([countryCode, priceSources]) => {
        return Object.entries(priceSources.sources || {}).map(([sourceName, sourceInfo]) => {
          const priceInHKD = exchangeRates ? convertPriceToHKD(sourceInfo.price, countryCode, exchangeRates) : null;
          if (priceInHKD !== null) {
            const countryName = countryData[countryCode]?.name || countryCode;
            return {
              name: `${countryName}${sourceName}`,
              price: parseFloat(priceInHKD)
            };
          }
          return null;
        });
      })
      .filter(entry => entry !== null)
      .sort((a, b) => a.price - b.price); // Sort by price in ascending order
  };

  const chartData = generateSortedChartData();

  const bookmarkProduct = async () => {
    console.log('Bookmark clicked for product:', productId);

    if (!user) {
      console.log('User not logged in, redirecting to signup');
      navigate('/signup');
      return;
    }

    const userId = user.uid;
    const db = getFirestore();
    const bookmarkRef = doc(db, 'bookmarks', userId);

    try {
      await setDoc(bookmarkRef, {
        products: arrayUnion(productId)
      }, { merge: true });
      console.log('Product bookmarked in Firestore:', productId);
      setSnackbarMessage('成功收藏產品');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error bookmarking the product:', error);
      setSnackbarMessage('收藏產品時出現錯誤');
      setSnackbarOpen(true);
    }
  };


  function RelatedItem({ item }) {
    return (
      <ListItem key={item.name} disablePadding>
        <ListItemButton onClick={() => handleCardClick(item)}>
            <ListItemText primaryTypographyProps={{ style: { textDecoration: 'underline', color: '#1976d2' } }}>{item.name}</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }

  const handleCardClick = (item) => {
      navigate(`/product/${item.objectID}`);
  };

   return (
    <>
    <Helmet>
      <title>{localProduct ? `${localProduct.name}各國價格比較` : '產品詳情'}</title>
      <meta property="og:title" content={localProduct ? `${localProduct.name}各國價格比較` : '產品詳情'} />
      <meta property="og:description" content={localProduct ? `查看${localProduct.name}的詳細資訊和各國價格比較` : '產品詳情'} />
      <meta property="og:image" content={localProduct && localProduct.imageUrl ? localProduct.imageUrl : 'default_image_url'} />
      <meta property="og:url" content={window.location.href} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <Card>
              {localProduct.imageUrl && (
                  <CardMedia
                      component="img"
                      height="250"
                      image={localProduct.imageUrl}
                      alt={localProduct.name}
                  />
              )}
              <CardContent>
                  <Typography gutterBottom variant="h4" component="h2">
                      {localProduct.name}各國價格比較
                  </Typography>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Typography variant="body1" color="textSecondary" component="p" sx={{ flexGrow: 1 }}>
                            更新日期：{formatDate(localProduct.updateTime)}
                        </Typography>
                        <Button variant="outlined" onClick={bookmarkProduct} sx={{ marginLeft: '4px' }}>
                            收藏
                        </Button>
                        <Button variant="outlined" onClick={shareProduct} sx={{ marginLeft: '4px' }}>
                            分享
                        </Button>
                      </Box>
              </CardContent>
          </Card>
      </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '1.1rem' }}>來源</TableCell>
                  <TableCell align="right" sx={{ fontSize: '1.1rem' }}>當地價格</TableCell>
                  <TableCell align="right" sx={{ fontSize: '1.1rem' }}>HKD</TableCell>
                  <TableCell align="right" sx={{ fontSize: '1.1rem' }}>連結</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableRows()}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Box sx={{ padding: '30px' }}>
        <Grid>
          <Typography variant="h5" component="h3" gutterBottom>常見問題</Typography>
          <div>
            {renderFAQs()}
          </div>
        </Grid>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={chartData} margin={{ top: 10, right: 20, left: 10, bottom: 30 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" angle={-90} textAnchor="end" height={80} />
                      <YAxis />
                      <Bar dataKey="price" fill="#8884d8" />
                  </BarChart>
        </ResponsiveContainer>
        <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
      />
      </Grid>
      <AdComponent />
      <Box sx={{ marginLeft: '16px' }}>
        <Typography variant="h5" component="h3">
          相關產品
        </Typography>

        <RelatedProducts
          recommendClient={recommendClient}
          indexName="products"
          objectIDs={[productId]}
          itemComponent={RelatedItem}
          maxRecommendations={6}
          translations={{ title: "" }}
        />
      </Box>
      </>
  );
}

export default ProductDetail;