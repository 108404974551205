// client/src/components/ProductPrices.js
import React from 'react';
import { Grid, MenuItem, Select, TextField, IconButton, Button, Paper, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { countryData } from '../utils/countryUtils';

const ProductPrices = ({ prices, sourceOptions, handlePriceChange, handleUrlChange, handleCountryChange, handleSourceChange, handleRemovePriceSource, handleAddPriceSource, handleAddPriceCountry, getAvailableCountriesForDropdown, unusedCountryOptions }) => {
    return (
        <Box style={{ marginBottom: '20px', borderBottom: '1px solid #eee' }}>
            <h4>價格</h4>
            {Object.entries(prices).map(([countryKey, sources], idx) => (
                    <Paper key={countryKey} variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Select
                                value={countryKey}
                                onChange={(e) => handleCountryChange(countryKey, e.target.value)}
                                fullWidth
                            >
                                {getAvailableCountriesForDropdown(countryKey).map((code) => (
                                    <MenuItem key={code} value={code}>
                                        {countryData[code].name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    {Object.entries(sources).map(([sourceKey, { price, url }], sourceIdx) => (
                        <React.Fragment key={sourceKey}>
                            <Grid item xs={12} sm={8} container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Select
                                        value={sourceKey}
                                        onChange={(e) => handleSourceChange(countryKey, sourceKey, e.target.value)}
                                        fullWidth
                                    >
                                        {sourceOptions.map((option, index) => (
                                            <MenuItem key={index} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={5} sm={3}>
                                    <TextField
                                        label="價格"
                                        type="number"
                                        value={price || ''}
                                        onChange={(e) => handlePriceChange(countryKey, sourceKey, e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={5} sm={4}>
                                    <TextField
                                        label="來源網址"
                                        value={url || ''}
                                        onChange={(e) => handleUrlChange(countryKey, sourceKey, e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleRemovePriceSource(countryKey, sourceKey)}
                                        size="large">
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => handleAddPriceSource(countryKey)}
                            size="small"
                        >
                            新增來源
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            ))}
                <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddPriceCountry}
                    size="small"
                    disabled={unusedCountryOptions().length === 0}
                >
                    新增國家和來源
                </Button>
        </Box>
    );
};

export default ProductPrices;
