// client/src/utils/auth.js
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail // Importing the Firebase function
} from 'firebase/auth';

export const signUpWithEmailPassword = async (email, password) => {
  const auth = getAuth();
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  // Send verification email
  await sendEmailVerification(userCredential.user);
  return userCredential;
};

export const signInWithEmailPassword = (email, password) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

// Added function for sending password reset email
export const sendPasswordResetEmail = (email) => {
  const auth = getAuth();
  return firebaseSendPasswordResetEmail(auth, email);
};
