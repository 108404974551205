// client/src/components/ProductTags.js
import React from 'react';
import { Box, TextField, Chip } from '@mui/material';

const ProductTags = ({ tags, newTag, handleRemoveTag, handleNewTagChange, handleNewTagKeyPress }) => {
    return (
        <Box style={{ marginBottom: '20px', borderBottom: '1px solid #eee' }}>
            <h4>標籤</h4>
            <Box mb={2}>
                {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleRemoveTag(index)}
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                    />
                ))}
            </Box>
            <TextField
                label="新增標籤"
                value={newTag}
                onChange={handleNewTagChange}
                onKeyPress={handleNewTagKeyPress}
                fullWidth
            />
        </Box>
    );
};

export default ProductTags;
