// src/utils/currencyUtils.js
import { getCurrencyByCountryCode } from './countryUtils';

export const convertPriceToHKD = (price, country, exchangeRates) => {
  const currency = getCurrencyByCountryCode(country);
  const numericPrice = parseFloat(price);
  if (isNaN(numericPrice)) {
    console.log(`Invalid price format for ${country}:`, price);
    return null;
  }
  if (currency === 'HKD') {
    return numericPrice.toFixed(1);
  }
  if (!exchangeRates[currency]) {
    // console.log(`Exchange rate not available for ${country} (API Key: ${currency})`);
    return null;
  }
  const rate = exchangeRates[currency];
  return (numericPrice / rate).toFixed(1);
};

export const calculatePercentageDifference = (priceHKD, priceOtherInHKD) => {
  if (priceHKD === null || priceOtherInHKD === null) {
    return '';
  }
  return `(${((priceOtherInHKD - priceHKD) / priceHKD * 100).toFixed()}%)`;
};