// client/src/components/BrandListing.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  List,
  ListItem,
  Divider,
  Paper,
  ListItemText,
  Grid
} from '@mui/material';
import { categorizeBrands } from '../utils/brandUtils';

const brandsData = [
  'YSL',
  'Sony',
  'Panasonic',
  'Insta360',
  'Dyson',
  'Marshall',
  'Columbia',
  'lululemon',
  '小米',
  'Apple',
  'Disney',
  '一蘭拉麵',
  'Tenga',
  'Chloe',
  'Owndays',
  'Gasoline',
  'Tesla',
  'Universal Studio',
  'Ray-Ban',
  'Garmin',
  'Netflix',
  'Spotify',
  'Google',
];

function BrandListing() {
  const [categorizedBrands, setCategorizedBrands] = useState({});

  useEffect(() => {
    const categories = categorizeBrands(brandsData);
    setCategorizedBrands(categories);
  }, []);

  const sortedCategories = Object.entries(categorizedBrands).sort(([keyA], [keyB]) => {
    if (keyA === '其他') return 1;
    if (keyB === '其他') return -1;
    return 0;
  });

  return (
    <>
    <Helmet>
      <title>品牌列表</title>
      <link rel="canonical" href={`${window.location.origin}/brands`} />
    </Helmet>
    <Grid container spacing={2} style={{ padding: 20 }}>
      {sortedCategories.map(([category, brands]) => (
        <Grid item xs={12} md={6} key={category}>
          <Paper style={{ padding: 10 }}>
            <Typography variant="h6">{category}</Typography>
            <List>
              {brands.map((brand, index) => (
                <React.Fragment key={brand}>
                  <ListItem button component="a" href={`/brand/${encodeURIComponent(brand)}`}>
                    <ListItemText primary={brand} />
                  </ListItem>
                  {index < brands.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      ))}
    </Grid>
    </>
  );
}

export default BrandListing;
