// client/src/TaggedProductList.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProductList from './ProductList';
import { BASE_URL } from '../config';
import useLoading from '../hooks/useLoading';
import LoadingIndicator from '../components/LoadingIndicator';
import { setCache, getCache } from '../utils/cacheUtils';

function TaggedProductList() {
    const { tag } = useParams();
    const [products, setProducts] = useState([]);
    const { loading, startLoading, stopLoading } = useLoading();
    const title = `${tag}產品價格比較`;
    const canonicalUrl = `${window.location.origin}/tag/${tag}`;
    const [isFromCache, setIsFromCache] = useState(false);

    useEffect(() => {
        const cacheKey = `products_by_tag_${tag}`;
        const cachedProducts = getCache(cacheKey);

        const fetchProductsByTag = async () => {
            try {
                startLoading();
                const response = await axios.get(`${BASE_URL}/api/products/byTag/${tag}`);
                setProducts(response.data);
                setCache(cacheKey, response.data);
            } catch (error) {
                console.log('Error fetching products by tag:', error);
            } finally {
                stopLoading();
            }
        };

        if (!cachedProducts) {
            fetchProductsByTag();
            setIsFromCache(false);
        } else {
            setProducts(cachedProducts);
            stopLoading();
            setIsFromCache(true);
        }
    }, [tag, startLoading, stopLoading]);

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <ProductList
            products={products}
            title={title}
            canonicalUrl={canonicalUrl}
            isFromCache={isFromCache}
        />
    );
}

export default TaggedProductList;
