// client/src/components/Footer.js
import React from 'react';
import { AppBar, Toolbar, Typography, Link, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const footerLinks = [
    { label: '關於我們', path: '/aboutus' },
    { label: '使用條款', path: '/terms' },
    { label: '聯絡我們', path: 'mailto:hi@schooldays.xyz' }
  ];

  const handleLinkClick = (path) => {
    if (path.startsWith('mailto:')) {
      window.location.href = path;
    } else if (path.startsWith('http')) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: theme.palette.background.paper, marginTop: '16px', padding: isMobile ? '10px' : '0px' }}>
      <Toolbar>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
            {footerLinks.map((link) => (
              <Link 
                key={link.label} 
                component="button"
                variant="body2"
                onClick={() => handleLinkClick(link.path)}
                style={{ margin: '0 10px', color: theme.palette.text.primary }}>
                {link.label}
              </Link>
            ))}
          </Grid>
          <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
            <Typography variant="body2" color="textSecondary">
              產品數量: 64 | 會員人數: 9
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
            <Typography variant="body2" color="textSecondary">
              © 2024 一價網. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
            <Typography variant="body2" color="textSecondary">
              版本 v1.2.4 (10/1/2024)
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
