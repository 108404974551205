// client/src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAMa13h43HPO1e3Qo6dDbWQM6VKqSCiMn4",
  authDomain: "premium-ea3f2.firebaseapp.com",
  projectId: "premium-ea3f2",
  storageBucket: "premium-ea3f2.appspot.com",
  messagingSenderId: "570890852318",
  appId: "1:570890852318:web:d94023acbc40ab39a4af4f",
  measurementId: "G-SWM7E65PN3"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { db, auth };
