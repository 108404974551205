// src/hooks/useProductForm.js
import { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';
import { countryData } from '../utils/countryUtils';

const useProductForm = (initialProduct) => {
    const [product, setProduct] = useState(initialProduct);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedSources, setSelectedSources] = useState({});
    const [newTag, setNewTag] = useState('');

    const handlePriceChange = (country, source, value) => {
        const updatedProduct = { ...product };
        updatedProduct.prices[country][source].price = parseFloat(value);
        setProduct(updatedProduct);
    };

    const handleUrlChange = (country, source, value) => {
        const updatedProduct = { ...product };
        updatedProduct.prices[country][source].url = value;
        setProduct(updatedProduct);
    };

    const handleFaqChange = (index, type, value) => {
        const updatedFaqs = [...product.faqs];
        updatedFaqs[index][type] = value;
        setProduct({ ...product, faqs: updatedFaqs });
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    };

    const handleRemoveTag = (index) => {
        setProduct({ ...product, tags: product.tags.filter((_, i) => i !== index) });
    };

    const handleCountryChange = (countryKey, newCountry) => {
        setSelectedCountry(newCountry);
        const updatedPrices = {...product.prices};
        updatedPrices[newCountry] = {...updatedPrices[countryKey]};
        delete updatedPrices[countryKey];
        setProduct({ ...product, prices: updatedPrices });
    };

    const handleSourceChange = (countryKey, sourceKey, newSource) => {
        setSelectedSources(prev => ({ ...prev, [countryKey]: newSource }));
        const updatedSources = {...product.prices[countryKey]};
        updatedSources[newSource] = {...updatedSources[sourceKey]};
        delete updatedSources[sourceKey];
        setProduct(prevProduct => ({
            ...prevProduct,
            prices: {
                ...prevProduct.prices,
                [countryKey]: updatedSources
            }
        }));
    };

    const handleRemovePriceSource = (countryKey, sourceKey) => {
        const updatedPrices = { ...product.prices };
        delete updatedPrices[countryKey][sourceKey];
        setProduct({ ...product, prices: updatedPrices });
    };

    const unusedCountryOptions = () => {
        const usedCountries = new Set(Object.keys(product.prices));
        return Object.keys(countryData).filter(country => !usedCountries.has(country));
    };

    const handleAddPriceSource = (countryKey) => {
        const updatedPrices = { ...product.prices };
        const newSourceKey = '新來源';

        // Check if the country exists
        if (updatedPrices[countryKey]) {
            // Add new source to the existing country
            updatedPrices[countryKey][newSourceKey] = { price: 0, url: '' };
        } else {
            // If the country doesn't exist, create a new entry with the new source
            updatedPrices[countryKey] = { [newSourceKey]: { price: 0, url: '' } };
        }

        setProduct({ ...product, prices: updatedPrices });
    };

    const handleAddPriceCountry = () => {
        const unusedCountries = unusedCountryOptions();
        if (unusedCountries.length > 0) {
            const newCountryKey = unusedCountries[0]; // Use the first unused country
            handleAddPriceSource(newCountryKey);
        } else {
            console.log('No more countries to add.');
        }
    };

    const handleNewTagChange = (e) => {
        setNewTag(e.target.value);
    };

    const handleNewTagKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (newTag) {
                setProduct({ ...product, tags: [...product.tags, newTag] });
                setNewTag('');
            }
        }
    };

    const generateQuestion = async (index) => {
        const existingQuestion = product.faqs[index].question;
        if (existingQuestion) {
            console.log('Question already exists:', existingQuestion);
            return;
        }
        try {
            const response = await axios.post(`${BASE_URL}/api/openai/generate-question`, {
                productName: product.name,
                previousQuestions: product.faqs.map(faq => faq.question).filter(q => q)
            });
    
            const question = response.data.question;
            handleFaqChange(index, 'question', question);
        } catch (error) {
            console.error('Error generating question:', error);
        }
    };
    
    const generateAnswer = async (index) => {
        const question = product.faqs[index].question;
        if (!question) {
            console.error('No question to generate an answer for.');
            return;
        }
        try {
            const response = await axios.post(`${BASE_URL}/api/openai/generate-answer`, {
                question
            });

            const answer = response.data.answer;
            handleFaqChange(index, 'answer', answer);
        } catch (error) {
            console.error('Error generating answer:', error);
        }
    };

    return {
        product,
        setProduct,
        newTag,
        setNewTag,
        handlePriceChange,
        handleUrlChange,
        handleFaqChange,
        handleChange,
        handleRemoveTag,
        handleCountryChange,
        handleSourceChange,
        handleRemovePriceSource,
        handleAddPriceSource,
        handleAddPriceCountry,
        unusedCountryOptions,
        handleNewTagChange,
        handleNewTagKeyPress,
        generateQuestion,
        generateAnswer
    };
};

export default useProductForm;
