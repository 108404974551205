// client/src/components/SimpleLogin.js
import React, { useState } from 'react';
import { Button, TextField, Container, Typography, CircularProgress, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailPassword, sendPasswordResetEmail } from '../utils/auth';

function SimpleLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const isNetworkError = (error) => error.code === 'auth/network-request-failed';

  const handleSignIn = async () => {
    setLoading(true);
    try {
      await signInWithEmailPassword(email, password);
      navigate('/');
    } catch (error) {
      console.error('Error during sign-in:', error);
      if (isNetworkError(error)) {
        setMessage('網絡連接失敗，請檢查您的網絡設置');
      } else {
        setMessage('登入時出現錯誤');
      }
    }
    setLoading(false);
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setMessage('請輸入您的電子郵件以重設密碼');
      return;
    }
    try {
      await sendPasswordResetEmail(email);
      setMessage('重設密碼的郵件已發送');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      if (isNetworkError(error)) {
        setMessage('網絡連接失敗，無法發送重設密碼郵件');
      } else {
        setMessage('發送重設密碼郵件時出現錯誤');
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" style={{ margin: '20px 0' }}>登入</Typography>
      <TextField
        label="電子郵件"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
      />
      <TextField
        label="密碼"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loading}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSignIn}
        disabled={loading || email === '' || password === ''}
        startIcon={loading ? <CircularProgress size={24} /> : null}
      >
        登入
      </Button>
      <Typography style={{ marginTop: 20 }}>
        <Link href="#" onClick={handleForgotPassword} style={{ cursor: 'pointer' }}>
          忘記密碼
        </Link>
      </Typography>
      {message && <Typography style={{ marginTop: 20 }}>{message}</Typography>}
    </Container>
  );
}

export default SimpleLogin;
