// src/utils/cacheUtils.js
export const setCache = (key, data) => {
    const cacheData = {
      timestamp: Date.now(),
      data: data,
    };
    localStorage.setItem(key, JSON.stringify(cacheData));
  };
  
  // export const getCache = (key, expiryTime = 0 * 60 * 1000) => {
  export const getCache = (key, expiryTime = 1 * 60 * 1000) => {
    const cacheData = localStorage.getItem(key);
    if (!cacheData) return null;
  
    const { timestamp, data } = JSON.parse(cacheData);
    const isExpired = Date.now() - timestamp > expiryTime;
    return isExpired ? null : data;
  };
  