// src/hooks/useExchangeRates.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useExchangeRates = () => {
    const [exchangeRates, setExchangeRates] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExchangeRates = async () => {
            setLoading(true);
            try {
                const BASE_URL = process.env.NODE_ENV === 'production'
                    ? process.env.REACT_APP_API_BASE_URL
                    : 'http://127.0.0.1:5001/premium-ea3f2/asia-east2/api';

                const response = await axios.get(`${BASE_URL}/api/exchangeRates`);
                setExchangeRates(response.data);
            } catch (err) {
                setError(err);
                console.error('Error fetching exchange rates:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchExchangeRates();
    }, []);

    return { exchangeRates, loading, error };
};

export default useExchangeRates;
