// client/src/components/SignUp.js
import React, { useState } from 'react';
import { Button, TextField, Container, Typography, CircularProgress, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signUpWithEmailPassword } from '../utils/auth';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async () => {
    setLoading(true);
    setMessage(''); // Clear any previous messages
    try {
      await signUpWithEmailPassword(email, password);
      setMessage('註冊成功，請驗證您的電子郵件');
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setMessage('此電子郵件已被使用。如果這是您的郵件，請嘗試重置密碼。');
      } else {
        console.error('Error during sign-up:', error);
        setMessage('註冊時出現錯誤');
      }
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" style={{ margin: '20px 0' }}>註冊</Typography>
      <TextField
        label="電子郵件"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
      />
      <TextField
        label="密碼"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loading}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSignUp}
        disabled={loading || email === '' || password === ''}
        startIcon={loading ? <CircularProgress size={24} /> : null}
      >
        註冊
      </Button>
      {message && <Typography style={{ marginTop: 20 }}>{message}</Typography>}
      <Typography style={{ marginTop: 20 }}>
        已經有帳號？ <Link href="/login" onClick={() => navigate('/login')}>前往登入頁面</Link>
      </Typography>
    </Container>
  );
}

export default SignUp;
