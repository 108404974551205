// client/src/components/AdComponent.js
import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div align="center">
      <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-3140357642615737"
          data-ad-slot="3849641287"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
  </div>
    );
};

export default AdComponent;
