// client/src/BrandProductList.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProductList from './ProductList';
import { BASE_URL } from '../config';
import useLoading from '../hooks/useLoading';
import LoadingIndicator from './LoadingIndicator';
import { setCache, getCache } from '../utils/cacheUtils';

function BrandProductList({ handleOpenAddDialog }) {
    const { brand } = useParams();
    const [products, setProducts] = useState([]);
    const { loading, startLoading, stopLoading } = useLoading();
    const title = `${brand}產品價格比較`;
    const canonicalUrl = `${window.location.origin}/brand/${brand}`;
    const [isFromCache, setIsFromCache] = useState(false);

    useEffect(() => {
        const cacheKey = `products_by_brand_${brand}`;
        const cachedProducts = getCache(cacheKey);

        const fetchProductsByBrand = async () => {
            try {
                startLoading();
                const response = await axios.get(`${BASE_URL}/api/products/byBrand/${brand}`);
                setProducts(response.data);
                setCache(cacheKey, response.data);
            } catch (error) {
                console.log('Error fetching products by brand:', error);
            } finally {
                stopLoading();
            }
        };

        if (!cachedProducts) {
            fetchProductsByBrand();
            setIsFromCache(false);
        } else {
            setProducts(cachedProducts);
            stopLoading();
            setIsFromCache(true);
        }
    }, [brand, startLoading, stopLoading]);

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <ProductList
            products={products}
            title={title}
            canonicalUrl={canonicalUrl}
            isFromCache={isFromCache}
        />
    );
}

export default BrandProductList;
