// client/src/components/NavBar.js
import React, { useState } from 'react';
import {
  AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem,
  TextField, useTheme, InputAdornment
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

export const NavBar = ({ user, onLogout }) => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const drawerItems = [
    { label: '品牌', path: '/brands' },
    { label: '科技', path: '/tag/科技' },
    { label: '影音', path: '/tag/影音' },
    { label: '時裝', path: '/tag/時裝' },
    { label: '家電', path: '/tag/家電' },
    { label: '運動', path: '/tag/運動' },
    { label: '汽車', path: '/tag/汽車' },
    { label: '旅遊', path: '/tag/旅遊' },
    { label: '匯率', path: '/exchange-rates' },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
      setDrawerOpen(false);
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: theme.palette.primary.main }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
        <img 
          src="/logo512.png" 
          alt="Logo" 
          style={{ height: '30px', marginRight: '10px', cursor: 'pointer' }} 
          onClick={() => navigate('/')}
        />
        <Typography variant="h6" noWrap onClick={() => navigate('/')} style={{ cursor: 'pointer', flexShrink: 1 }}>
          一價網
        </Typography>
        {/* Search bar */}
        <form onSubmit={handleSearchSubmit} style={{ flexGrow: 1, marginLeft: '10px' }}>
          <TextField
            fullWidth
            placeholder="你在找甚麼？試試搜尋"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: { color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.15)', borderRadius: theme.shape.borderRadius }
            }}
          />
        </form>
        <IconButton color="inherit" onClick={() => user ? navigate('/profile') : navigate('/signup')}>
          <AccountCircleIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor='left' open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {drawerItems.map((item) => (
            <ListItem button key={item.label} onClick={() => {
              navigate(item.path);
              handleDrawerToggle();
            }}>
              <Typography variant="h6">
                {item.label}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default NavBar;
