// src/utils/ccountryUtils.js
export const countryData = {
    HK: { name: '香港', flag: '🇭🇰', currency: 'HKD' },
    JP: { name: '日本', flag: '🇯🇵', currency: 'JPY' },
    UK: { name: '英國', flag: '🇬🇧', currency: 'GBP' },
    CA: { name: '加拿大', flag: '🇨🇦', currency: 'CAD' },
    AU: { name: '澳洲', flag: '🇦🇺', currency: 'AUD' },
    TW: { name: '台灣', flag: '🇹🇼', currency: 'TWD' },
    SG: { name: '新加坡', flag: '🇸🇬', currency: 'SGD' },
    US: { name: '美國', flag: '🇺🇸', currency: 'USD' },
    CN: { name: '中國', flag: '🇨🇳', currency: 'CNY' },
};

// Utility function to get the country chinese name by country code
export const getNameByCountryCode = (code) => countryData[code]?.name || code;

// Utility function to get the flag by country code
export const getFlagByCountryCode = (code) => countryData[code]?.flag || code;

// Utility function to get the currency by country code
export const getCurrencyByCountryCode = (code) => countryData[code]?.currency || '';