// client/src/utils/brandUtils.js
export const categorizeBrands = (brandsArray) => {
  // Example categorization logic with Traditional Chinese categories
  const categories = {
    '科技': ['Apple', 'Google'],
    '影音': ['Sony', 'BEOPLAY', 'Insta360', 'Marshall', 'Netflix', 'Spotify'],
    '時裝': ['YSL', 'Chloe', 'Owndays', 'Ray-Ban'],
    '家電': ['Dyson', 'Panasonic','小米'],
    '運動': ['lululemon','Columbia', 'Garmin'],
    '汽車': ['Tesla','Gasoline'],
    '旅遊': ['Disney', 'Universal Studio','LOJEL'],
  };

  // Initialize the '其他' category
  const result = { '其他': [] };

  // Group brands by the defined categories
  brandsArray.forEach(brand => {
    let found = false;
    for (const category in categories) {
      if (categories[category].includes(brand)) {
        result[category] = result[category] || [];
        result[category].push(brand);
        found = true;
        break;
      }
    }
    if (!found) {
      result['其他'].push(brand);
    }
  });

  return result;
};
