// client/src/SearchResults.js
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import ProductList from '../components/ProductList';
import { Helmet } from 'react-helmet';
import LoadingIndicator from '../components/LoadingIndicator';
import useLoading from '../hooks/useLoading';

const algoliaClient = algoliasearch('80HKEWBAW9', 'f1f23ee825d8fb063f46c14cd426c67c');
const index = algoliaClient.initIndex('products');

function SearchResults({ user }) {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const [products, setProducts] = useState([]);
  const [queryID, setQueryID] = useState('');
  const { loading, startLoading, stopLoading } = useLoading();

  useEffect(() => {
    const fetchProductsBySearch = async () => {
      startLoading();
      try {
        console.log(`Searching for: ${searchQuery}`);
        const userToken = user ? user.uid : 'anonymous';
        const searchResults = await index.search(searchQuery, {
          userToken: userToken,
          clickAnalytics: true
        });
        const formattedProducts = searchResults.hits.map(hit => ({ ...hit, id: hit.objectID }));
        setProducts(formattedProducts);
        setQueryID(searchResults.queryID); // Set queryID state here
        console.log(`Products fetched for search query '${searchQuery}':`, formattedProducts, `Query ID: ${searchResults.queryID}`);
        // Push view event to GTM dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Hits Viewed', 
          // Add any additional data you want to send
          query: searchQuery,
          hits: formattedProducts.length
        });
      } catch (error) {
        console.error('Error fetching products for search:', error);
      } finally {
        stopLoading();
      }
    };

    if (searchQuery) {
      fetchProductsBySearch();
    }
  }, [searchQuery, startLoading, stopLoading, user]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>{`${searchQuery}搜尋結果`}</title>
        <link rel="canonical" href={`${window.location.origin}/search?q=${encodeURIComponent(searchQuery)}`} />
      </Helmet>
      <ProductList products={products} search={searchQuery}
                   searchQuery={searchQuery} queryID={queryID} />
    </>
  );
}

export default SearchResults;
