// src/AboutUs.js
import React from 'react';

const AboutUs = () => {
  const styles = {
    container: {
      padding: '20px',
      lineHeight: '1.6',
      fontSize: '16px',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
    }
  };

  document.title = `關於我們`;

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>關於我們</h1>
      <h2>一價網簡介</h2>
        <p>
        歡迎來到我們的網站，一個專為喜愛線上購物的你設計的比價平台。只要告訴我們你想購買的商品，我們將為你提供一個全面的價格比較，確保你獲得最佳的價格。作為我們的會員，你將能實時追蹤最新的優惠和功能更新，讓你的購物體驗更上一層樓。
        </p>
        <p>
        我們的目標是為你比較相同產品在全球各地官方網站及其在不同平台（如亞馬遜Amazon）上的官方網店所銷售的價格。由於生活水平及匯率的不同，官方網站在各銷售相同產品都會有不同價格，我們為你提供最新的訊息，幫助你作出明智的購物決策。
        </p>

        <h2>為什麼我們專注於官方網站和平台？</h2>
        <p>
        這是因為市面上存在眾多非官方購物渠道，它們銷售著各種版本的商品，如行水貨、水貨等，這些商品的價格往往難以相互比較。因此，為了確保價格比較的準確性和可靠性，我們選擇僅比較官方網店及平台上的官方網店的商品價格。
        </p>

        <h2>如何比較同一種商品但有有不同型號或個人化選項？</h2>
        <p>
        當同一商品存在不同型號或個人化選項時，我們將選擇特定的標準型號作為比較基準。這是因為即使存在不同選項，標準型號的價格通常已足夠代表該產品的整體價格差異。例如，若蘋果手錶Apple Watch 45mm在美國的價格是最便宜的，那麼即使是具有GPS功能的版本，在美國也是價格最低的。這樣的策略可以使我們的比較更加直觀和實用。
        </p>
        <p>
        在我們的平台上，我們致力於提供透明、準確的價格信息，讓你在購買時更有信心。謝謝你選擇我們的服務，開始享受智慧型的購物之旅吧！
        </p>
        <p>聯絡我們：hi@schooldays.xyz</p>
    </div>
  );
}

export default AboutUs;