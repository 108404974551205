// client/src/components/AdminProductManagement.js
import React, { useEffect, useState } from 'react';
import { Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useLoading from '../hooks/useLoading';
import LoadingIndicator from './LoadingIndicator';
import { BASE_URL } from '../config';

const AdminProductManagement = () => {
    const [products, setProducts] = useState([]);
    const { loading, startLoading, stopLoading } = useLoading();
    const navigate = useNavigate();

    const styles = {
        container: {
            padding: '20px',
            lineHeight: '1.6',
            fontSize: '16px',
        },
        header: {
            textAlign: 'center',
            marginBottom: '20px',
        }
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                if (!idTokenResult.claims.admin) {
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            startLoading();
            try {
                const response = await axios.get(`${BASE_URL}/api/products`);
                console.log('Fetched products for admin:', response.data);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products for admin:', error);
            }
            stopLoading();
        };

        fetchData();
    }, [startLoading, stopLoading]);

    const handleCreateNewProduct = () => {
        navigate('/create');
    };

    const handleEdit = (product) => {
        console.log("Editing product:", product);
        navigate(`/edit/${product.id}`);
    };

    const handleDelete = async (productId) => {
        console.log("Moving product to removedProducts with ID:", productId);
        startLoading();
        try {
            await axios.delete(`${BASE_URL}/api/products/moveToRemoved/${productId}`);
            console.log(`Product with ID: ${productId} has been moved to removedProducts.`);
            setProducts(products.filter(product => product.id !== productId));
        } catch (error) {
            console.error(`Error moving product with ID: ${productId} to removedProducts`, error);
        }
        stopLoading();
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <div style={styles.container}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={styles.header}>產品管理</h2>
                <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<AddCircleOutlineIcon />} 
                    onClick={handleCreateNewProduct}
                >
                    新增
                </Button>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>品牌</TableCell>
                        <TableCell>名稱</TableCell>
                        <TableCell>操作</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product) => (
                        <TableRow key={product.id}>
                            <TableCell>{product.brand}</TableCell>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>
                                <Button onClick={() => handleEdit(product)}>編輯</Button>
                                <Button onClick={() => handleDelete(product.id)}>刪除</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default AdminProductManagement;
